@import '../base.scss';

.bubble {
  position: relative;
  animation: scaleUp 500ms 1 cubic-bezier(0, 0.55, 0.45, 1);
  opacity: 0;
  animation-fill-mode: forwards;
  animation-play-state: paused;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    outline: none;
  }

  &-wrapper {
    transition: transform 300ms ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  &-item {
    position: absolute;
  }

  &-1 {
    width: 280px;
    height: 280px;

    &-item {
      &-0 {
        top: 0;
        left: 0;
        animation: skewXY 1.8s infinite linear alternate;
      }

      &-1 {
        z-index: 1;
        left: 0;
        bottom: 20px;
        animation: skewYX 1.5s infinite linear alternate;
        mix-blend-mode: multiply;
      }

      &-2 {
        top: 50%;
        margin-top: -80px;
        z-index: 2;
        left: 32px;
        width: 200px;
      }

      &-3 {
        top: 15px;
        left: 79px;
        z-index: 1;
        color: $white;
        font-size: 20px;
      }

      &-4 {
        bottom: 68px;
        left: -15px;
        z-index: 1;
        mix-blend-mode: multiply;
        animation: skewZ 1.5s infinite linear alternate-reverse;
      }
    }
  }

  &-2 {
    &-bg {
      width: 198px;
      height: 252px;
    }

    &-shape-top {
      top: 0;
      left: 0;
      mix-blend-mode: multiply;
      animation: skewYX 2s infinite linear alternate-reverse;
    }

    &-bottom {
      bottom: 0;
      left: 0;
      mix-blend-mode: multiply;
      animation: skewZ 1.5s infinite linear alternate-reverse;
    }

    &-img {
      bottom: 0;
      right: 0;
    }

    &-text {
      font-size: 20px;
      color: $white;
      top: 15px;
      left: 0;
      right: 25px;
    }
  }

  &-3 {
    width: rem(235px);
    height: rem(270px);

    &-bg,
    &-bg-bottom {
      width: 100%;
      left: 0;
      height: rem(200px);
    }

    &-bg {
      height: rem(200px);
      width: rem(235px);
      top: 0;
      z-index: 1;
    }

    &-bg-bottom {
      height: rem(181px);
      bottom: 0;
    }

    &-pic {
      top: 27px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }

    &-text {
      color: $white;
      font-size: 20px;
      left: 0;
      right: 0;
      bottom: 20px;
      z-index: 1;
      text-align: center;
    }
  }

  &-4 {
    width: 270px;
    height: 230px;

    &-bg {
      width: 100%;
      height: 220px;
      left: 0;
      top: 0;
    }

    &-left {
      width: 157px;
      height: 207px;
      left: -10px;
      bottom: 10px;

      path {
        mix-blend-mode: multiply;
      }
    }

    &-text {
      color: $white;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: rem(20px);
    }

    &-play {
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: rem(55px);
      filter: drop-shadow(0 0 4px $black);
    }
  }

  &-5 {
    &-robot {
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-text {
      font-size: 20px;
      color: $white;
      top: 35px;
      left: 40px;
      right: 0;
      text-align: center;

      > span {
        display: inline-block;
        &:first-letter {
          color: $color-orange;
        }
      }
    }
  }

  &-6 {
    width: 275px;
    height: 257px;

    &-bg {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &-top {
      width: 200px;
      height: 84px;
      top: -12px;
      left: 10px;
      mix-blend-mode: multiply;
    }

    &-text {
      color: $white;
      font-size: 20px;
      top: 25px;
      left: 0;
      right: 53px;
      text-align: center;
    }
  }

  &-7 {
    width: rem(270px);
    height: rem(240px);

    .case-study-icon {
      left: 28%;
      top: 12%;
    }

    &-bg {
      top: 0;
      left: 0;
      width: rem(270px);
      height: rem(250px);
    }

    &-shape-left {
      bottom: 5px;
      left: 50%;
      margin-left: -25%;
      width: 197px;
      height: 93px;
    }

    &-text {
      bottom: 18px;
      left: 50px;
      right: 0;
    }
  }

  &-8 {
    width: 220px;
    height: 280px;

    &-bg {
      top: 0;
      left: 0;
      width: rem(219px);
      height: rem(270px);
    }

    &-shape-right {
      top: 0;
      right: 0;
      width: 111px;
      height: 145px;
      mix-blend-mode: multiply;
    }

    &-text {
      color: $white;
      font-size: 20px;
      top: 40px;
      right: 20px;
    }
  }

  &-9 {
    width: rem(200px);
    height: rem(250px);

    &-bg {
      width: 224px;
      height: 224px;
      top: 0;
      left: 0;
    }

    &-img {
      top: 0;
      left: 0;
      clip-path: url(#bubble-9-mask);
    }

    &-shape-right {
      width: 182px;
      height: 91px;
      top: -25px;
      right: 5px;
      z-index: 1;
      mix-blend-mode: multiply;
      animation: skewXY 1.8s infinite linear alternate;
    }

    &-text {
      top: 0;
      left: 0;
      right: 0;
      font-size: rem(20px);
      color: $white;
      text-align: center;
      z-index: 1;
    }
  }

  &-10 {
    width: 238px;
    height: 280px;

    &-bg {
      top: 0;
      left: 0;
      path {
        animation: skewYX 2s infinite linear alternate;
      }
    }

    &-shape-top {
      top: -25px;
      left: 0;
      mix-blend-mode: multiply;
      animation: skewXY 2s infinite linear alternate;
    }

    &-text {
      font-size: rem(20px);
      color: $white;
      top: 10px;
      left: 0;
      right: 10px;
    }
  }

  &-11 {
    &-shape-bottom {
      mix-blend-mode: multiply;
      animation: skewXY 2s infinite linear alternate;
    }

    &-text {
      color: $white;
      font-size: 20px;
      bottom: 49px;
      left: 0;
      right: 20px;
    }

    .case-study-icon {
      left: 90%;
      top: 44%;
    }
  }

  &-12 {
    width: rem(260px);
    height: rem(260px);

    .case-study-icon {
      left: 11%;
      top: 66%;
    }

    &-bg {
      top: 0;
      left: 0;
      animation: skewYX 2s infinite alternate ease-in-out;
    }

    &-img {
      top: 50%;
      left: 46%;
      transform: translate(-50%, -50%);
    }

    &-shape-top {
      top: -20px;
      left: 30px;
      mix-blend-mode: hard-light;
      animation: skewXY 2s 200ms infinite alternate ease-in-out;
    }

    &-text {
      top: rem(12px);
      left: 0;
      right: 0;
      text-align: center;
      color: $white;
      font-size: rem(20px);
    }
  }

  &-13 {
    width: rem(230px);
    height: rem(230px);

    &-bg {
      top: 0;
      left: 0;
    }

    &-shape-top {
      top: -25px;
      left: -10px;
      mix-blend-mode: multiply;
      animation: skewYX 2s infinite alternate ease-in-out;
    }

    &-text {
      color: $white;
      font-size: 19px;
      top: -14px;
      left: 15px;
      line-height: 1em;
    }
  }

  &-14 {
    width: 245px;
    height: 230px;

    &-bg {
      top: 0;
      left: 0;
    }

    &-shape {
      bottom: 0;
      left: 15px;
      mix-blend-mode: multiply;
      animation: skewXY 2s infinite alternate ease-in-out;
    }

    &-text {
      font-size: rem(18px);
      color: $white;
      bottom: 27px;
      left: 0;
      right: 20px;
    }
  }

  &-15 {
    width: 250px;
    height: 260px;

    &-bg {
      top: 0;
      left: 0;
    }

    &-shape {
      bottom: 0;
      left: 20px;
      mix-blend-mode: multiply;
      animation: skewXY 2s infinite alternate ease-in-out;
    }

    &-text {
      bottom: 10px;
      left: 0;
      right: 35px;
      font-size: rem(18px);
      color: $white;
      text-align: center;
    }
  }

  &-16 {
    width: rem(230px);
    height: rem(270px);

    .case-study-icon {
      left: 60%;
      top: 86%;
    }

    &-bg {
      top: 0;
      left: 0;
    }

    &-shape {
      top: -20px;
      left: -10px;
      opacity: 0.75;
      mix-blend-mode: multiply;
      animation: skewYX 2s infinite alternate ease;
    }

    &-text {
      top: 25px;
      left: 0;
      right: 40px;
      text-align: center;
      color: $white;
      font-size: rem(20px);
    }
  }

  &-17 {
    width: rem(265px);
    height: rem(250px);

    &-bg {
      top: 0;
      left: 0;
    }

    &-img {
      top: 0;
      left: 0;
      clip-path: url(#bubble-17-bg);
    }

    &-shape {
      bottom: 0;
      left: 35px;
      mix-blend-mode: multiply;
      animation: skewXY 2s infinite alternate ease-in-out;
    }

    &-text {
      font-size: 20px;
      color: $white;
      left: 0;
      right: 0;
      text-align: center;
      bottom: 20px;
    }
  }

  &-18 {
    width: 252px;
    height: 230px;

    &-bg {
      top: 0;
      left: 0;
    }

    &-shape {
      bottom: 0;
      left: 35px;
      mix-blend-mode: multiply;
      animation: skewXY 1.5s infinite alternate ease-in-out;
    }

    &-text {
      color: $white;
      font-size: 20px;
      left: 0;
      right: 30px;
      text-align: center;
      bottom: 25px;
    }
  }

  &-19 {
    width: rem(238px);
    height: rem(280px);

    &-bg {
      top: 0;
      left: 0;
    }

    &-shape {
      bottom: 0;
      left: 0;
      mix-blend-mode: multiply;
      animation: skewYX 1.5s infinite alternate ease;
    }

    &-text {
      bottom: 35px;
      left: 35px;
      font-size: rem(20px);
      color: $white;
    }
  }

  &-20 {
    width: 264px;
    height: 270px;

    &-bg {
      top: 0;
      left: 0;
    }

    &-shape {
      top: 0;
      left: 0;
      mix-blend-mode: multiply;
      animation: skewXY 1.5s infinite alternate ease;
    }

    &-text {
      top: 30px;
      left: 0;
      right: 40px;
      font-size: 20px;
      color: $white;
    }
  }

  &-21 {
    width: 230px;
    height: 280px;

    &-bg {
      top: 0;
      left: 0;
    }

    &-shape {
      top: -10px;
      left: 30px;
      mix-blend-mode: multiply;
      animation: skewXY 1.5s infinite alternate ease-in-out;
    }

    &-text {
      color: $white;
      font-size: rem(20px);
      top: 20px;
      left: 0;
      right: 20px;
    }
  }

  &-22 {
    width: 270px;
    height: 280px;

    &-bg {
      bottom: 0;
      left: 0;
      animation: skewYX 2s infinite alternate linear;
    }

    &-shape {
      top: 0;
      left: 0;
      mix-blend-mode: multiply;
      animation: skewXY 2s infinite alternate linear;
    }

    &-img {
      top: 20px;
      left: 0;
      clip-path: url(#bubble-22-mask);
      object-position: -10px -26px;
      background: #e1efe2;
    }

    &-text {
      bottom: 15px;
      left: 0;
      right: 0;
      font-size: 20px;
      color: $white;
    }
  }

  &-23 {
    width: 270px;
    height: 260px;

    &-bg {
      top: 0;
      left: 0;
    }

    &-shape {
      bottom: 0;
      left: 30px;
      mix-blend-mode: multiply;
      animation: skewXY 1.5s infinite alternate linear;
    }

    &-text {
      color: $white;
      font-size: 20px;
      bottom: 30px;
      left: 30px;
      right: 0;
    }
  }

  &-24 {
    width: 248px;
    height: 280px;

    &-bg {
      top: 0;
      left: 0;
      animation: skewXY 2s infinite alternate linear;
    }

    &-img {
      top: 42px;
      left: 26px;
    }

    &-shape {
      top: -30px;
      left: 0;
      mix-blend-mode: multiply;
      animation: skewYX 1.5s infinite alternate linear;
    }

    &-text {
      top: 10px;
      left: 0;
      right: 0;
      font-size: rem(20px);
    }
  }

  &-25 {
    width: 260px;
    height: 260px;

    &-bg {
      top: 0;
      left: 0;
    }

    &-shape {
      top: -20px;
      left: 0;
      mix-blend-mode: multiply;
      animation: skewYX 2s infinite alternate linear;
    }

    &-text {
      color: $white;
      font-size: 20px;
      top: 25px;
      left: 40px;
    }
  }

  &-26 {
    width: 250px;
    height: 260px;

    &-bg {
      top: 0;
      left: 0;
    }

    &-shape {
      top: -10px;
      left: -10px;
      mix-blend-mode: multiply;
      animation: skewYX 1.6s infinite linear alternate;
    }

    &-text {
      top: 10px;
      left: 30px;
      color: $white;
      font-size: 17px;
    }
  }

  &-27 {
    width: 265px;
    height: 240px;

    &-bg {
      top: 0;
      left: 0;
    }

    &-shape {
      top: -10px;
      left: 15px;
      mix-blend-mode: multiply;
      animation: skewXY 1.5s infinite linear alternate;
    }

    &-text {
      color: $white;
      font-size: 20px;
      top: 28px;
      left: 0;
      right: 0;
      text-align: center;
    }
  }

  &-28 {
    width: 245px;
    height: 245px;

    &-bg {
      top: 0;
      left: 0;
    }

    &-shape {
      top: -20px;
      left: 10px;
      mix-blend-mode: multiply;
    }

    &-text {
      top: 10px;
      left: 52px;
      color: $white;
      font-size: 20px;
    }
  }
}

@keyframes skewXY {
  0% {
    transform: skewX(8deg) skewY(-5deg);
  }
  100% {
    transform: skewX(-8deg) skewY(-5deg);
  }
}

@keyframes skewYX {
  0% {
    transform: skewY(5deg);
  }
  100% {
    transform: skewY(-5deg);
  }
}

@keyframes skewZ {
  0% {
    transform: skewX(-5deg) skewY(-5deg) rotate(-9deg);
  }
  100% {
    transform: skewX(5deg) skewY(5deg) rotate(20deg);
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.no-btn-styles {
  display: block;
  background: transparent;
  border: 1px solid transparent;
  padding: 0;
}

.smoke {
  position: absolute;
  width: 100px;
  height: 121px;
  background: url('../../assets/images/css_sprites-full.png') 0 0;
  animation: smokeRun 5s steps(104) infinite;
  animation-fill-mode: forwards;
  background-size: cover;
  top: -45px;
  right: 52px;
  opacity: 0.5;
  z-index: 3;
  transform: scaleX(-1);
}

@keyframes smokeRun {
  100% {
    background-position: -10400px 0;
  }
}

.case-study-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
