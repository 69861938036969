.color-base {
  color: #0c0c0c;
}

.color-red {
  color: #ff0000;
}

.color-white {
  color: $white;
}

.color-orange {
  color: $color-orange;
}

.color-blue {
  color: $color-blue;
}

.color-black {
  color: $black;
}
