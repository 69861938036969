@import '../base';

%abs-w-h-100 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popup {
  @extend %abs-w-h-100;
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  z-index: 1060;
  overflow: auto;

  @include media-breakpoint-down(lg) {
    padding: 0;
    overflow: hidden;
  }

  &-backdrop {
    @extend %abs-w-h-100;
    position: fixed;
    background: rgba($black, 0.65);
    animation: fadeIn 400ms 1 ease;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  &-shape {
    position: relative;
    flex: 1;
    max-width: rem(1100px);
    z-index: 1;
    animation: fromUp 500ms 1 ease-in-out;
    animation-fill-mode: forwards;

    @include media-breakpoint-down(lg) {
      height: 100vh;
    }

    & > svg {
      position: absolute;
      z-index: -1;
      @extend %abs-w-h-100;

      @include media-breakpoint-down(lg) {
        top: 100%;
        left: 100%;
        transform: scale(10);
        width: 100vh;
        height: 100vh;
      }
    }

    &-fluid {
      // max-width: none;
    }

    &-lg {
      max-width: 1100px;
    }

    &-block {
      @include media-breakpoint-down(lg) {
        padding: 0 !important;
      }
    }
  }

  &-close {
    position: fixed;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    width: 50px;
    padding: 0;
    z-index: 1;
    opacity: 0.85;
    animation: comeWithRotatation 500ms 420ms 1
      cubic-bezier(0.445, 0.05, 0.55, 0.95);
    animation-fill-mode: forwards;
    transform: rotate(-180deg) scale(0);
    filter: drop-shadow(0 2px 4px rgba($black, 0.5));
    transition: opacity 300ms ease;

    @include media-breakpoint-up(xl) {
      top: 30px;
      right: 35px;
    }
  }

  &-content {
    @extend %abs-w-h-100;
    position: absolute;
    right: 0;
    margin: 0 auto;
    max-width: 80%;
    padding-right: 15px;

    @include media-breakpoint-down(lg) {
      display: block !important;
      height: 100vh;
      overflow-y: auto;
      max-width: none;
      padding: 90px 20px 30px;
    }

    &-overflow-control {
      max-height: rem(380px);
      overflow: auto;
      margin-bottom: 13px;
      @include custom-scrollbar();

      &.md {
        max-height: rem(430px);
      }

      &.lg {
        max-height: rem(500px);
      }

      @include media-breakpoint-down(lg) {
        max-height: none !important;
        overflow: visible;
      }
    }

    &-body {
      @include media-breakpoint-down(lg) {
        max-width: none !important;
        margin-right: 0;
      }
    }

    &-img {
      width: 50%;

      @include media-breakpoint-down(lg) {
        width: 100%;
        max-width: 450px;
        margin: 0 auto;
        display: block;
      }
    }

    &-media {
      width: 70%;
      max-width: 450px;
      height: 230px;
      background: $black;

      &.lg {
        width: 100%;
        @include media-breakpoint-up(lg) {
          max-width: none;
          height: 310px;
        }
      }

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }

    &-title {
      font-size: 38px;
    }

    &-sentence {
      font-size: 19px;
    }

    &-abs {
      position: absolute;
      transform: translate(-50%, -50%);

      @include media-breakpoint-down(lg) {
        transform: none;
        position: static;
        width: 100% !important;
        height: 100vh;
        overflow-y: auto;
        max-width: none;
        padding: 90px 20px 30px;
      }
    }
  }

  &.is-hide &-backdrop {
    animation: fadeOut 400ms 500ms 1 ease;
    animation-fill-mode: forwards;
    opacity: 1;
  }

  &.is-hide &-shape {
    animation: goUp 500ms 1 ease-in-out;
    animation-fill-mode: forwards;
  }

  &.is-hide &-close {
    opacity: 0;
  }
}

@keyframes fromUp {
  0% {
    transform: translateY(-120%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes goUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-120%);
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

@keyframes comeWithRotatation {
  to {
    transform: rotate(0deg) scale(1);
  }
}
