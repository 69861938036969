@import '../base.scss';

.social-icons {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0 0 rem(20px);
  padding: 0;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

  li {
    margin: 0 rem(2px);
  }

  img {
    width: rem(26px);
    transition: transform 100ms ease;

    &:hover {
      transform: scale(0.92);
    }
  }
}
