@font-face {
  font-family: 'euphorigenic';
  src: url('../assets/fonts/Euphorigenic-Regular.svg#Euphorigenic-Regular')
      format('svg'),
    url('../assets/fonts/Euphorigenic-Regular.ttf') format('truetype'),
    url('../assets/fonts/Euphorigenic-Regular.woff') format('woff'),
    url('../assets/fonts/euphorigenic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/euphorigenic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
