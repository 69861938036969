@import '../base.scss';

.header {
  height: rem(600px);
  background: url(../../assets/images/header-bg-sm.jpg) no-repeat bottom center;
  background-size: cover !important;
  padding-top: rem(60px);
  overflow-x: hidden;

  @include media-breakpoint-up(lg) {
    height: rem(560px);
    background: url(../../assets/images/black-wave-bg.jpg) no-repeat bottom
      center;
  }

  &-text {
    color: $white;
  }

  &-logo {
    @include media-breakpoint-down(md) {
      flex: 0 0 104px;
    }
  }
}

.logo {
  &-wrapper {
    position: relative;
  }
  
  &-butterfly {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -19px;
    margin-right: -11px;
    max-width: 33px;
    transition: transform 4s ease;
    transform: translateX(14vw);

    @include media-breakpoint-up(lg) {
      margin-top: -30px;
      margin-right: -6px;
      max-width: 42px;
    }

    @media (min-width: $breakpoing-xxl) {
      transform: translateX(18vw);
    }

    @media (min-width: $breakpoing-xxxl) {
      transform: translateX(23vw);
    }
  }
}

.page-loader.is-hide + .header {
  .logo-butterfly {
    transform: translateX(0);
  }
}




.header a.logo-wrapper .img-fluid {
  max-width: 270px;
  height: auto;
  width: 100%;
}
