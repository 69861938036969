@import '../base.scss';

.content-section {
  background: $white;
  padding-bottom: 70px;
  position: relative;

  @include media-breakpoint-down(md) {
    padding-top: 70px;
  }

  &::after {
    content: '';
    position: absolute;
    height: 80px;
    bottom: -80px;
    left: 0;
    right: 0;
    background: url(../../assets/images/bottom-shape.png) no-repeat bottom
      center;
    background-size: cover;
  }

  &-title {
    font-size: rem(46px);
    color: $color-red;
    margin: 0 0 0.4em;
  }

  &-body {
    font-size: 20px;
    line-height: 1.5em;
    color: $color-base;
  }
}
