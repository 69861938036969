@import '../base.scss';

.btn {
  border-style: solid;
  padding: rem(6px) rem(15px);
  @include button-variant($color-pink, $color-pink);

  &:hover {
    text-decoration: none;
  }

  &:focus,
  &:active,
  &:active:focus {
    border-color: $color-pink;
    outline: none;
  }
}

.btn-primary {
  border: 1px solid;
  padding: 10px 16px;
  display: block;
  font-size: 20px;
  letter-spacing: 0.04em;
  background: transparent;
  @include button-outline-variant($white, $black);
  box-shadow: 0 2px 4px rgba($black, 0.2);

  &:focus {
    outline: none;
  }

  &-dark {
    @include button-outline-variant($black, $white);
  }
}
