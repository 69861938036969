@import '../base.scss';

.main-section {
  position: relative;
  z-index: 1;
  margin-top: rem(-210px);
  margin-bottom: rem(0px);

  @include media-breakpoint-up(lg) {
    margin-top: rem(-250px);
    margin-bottom: rem(-185px);
  }

  &-item {
    padding: 0 rem(15px);
    margin-bottom: rem(20px);

    @include media-breakpoint-up(lg) {
      margin-bottom: rem(70px);
    }

    @include media-breakpoint-down(sm) {
      width: 50%;
      margin-bottom: rem(-70px);
      padding: 0;

      &:nth-of-type(odd) {
        transform: scale(0.7) translateX(-20px);
      }

      &:nth-of-type(even) {
        transform: scale(0.7) translateX(-40px);
      }
    }

    @media (max-width: $breakpoint-xxs) {
      &:nth-of-type(odd) {
        transform: scale(0.62) translateX(-20px);
      }

      &:nth-of-type(even) {
        transform: scale(0.62) translateX(-40px);
      }
    }

    @include media-breakpoint-between(lg, lg) {
      &:last-child {
        margin-right: auto;
        margin-left: 60px;
      }
    }
  }

  &.service-page .main-section-item {
    @include media-breakpoint-down(sm) {
      &:nth-of-type(even) {
        transform: scale(0.65) translateX(0);
      }
    }

    @media (max-width: $breakpoint-xxs) {
      &:nth-of-type(even) {
        transform: scale(0.6) translateX(-10px);
      }
    }
  }

  &:not(.service-page) .main-section-item {
    @include media-breakpoint-down(sm) {
      &:nth-child(2),
      &:nth-child(8) {
        transform: scale(0.7) translateX(0px);
      }
    }

    @media (max-width: $breakpoint-xxs) {
      &:nth-child(2),
      &:nth-child(8) {
        transform: scale(0.62) translateX(0px);
      }
    }
  }
}
