.position-static {
  position: static;
}

.overflow-hidden {
  overflow: hidden;
}

.text-xx-small {
  font-size: xx-small;
}

.f-18 {
  font-size: 18px;
}

.overlay-button {
  position: relative;

  &-btn {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  @include media-breakpoint-down(xl) {
    max-width: none !important;
    width: 100% !important;
  }
}

.w-100 {
  width: 100% !important;
}

.h-230 {
  height: 230px !important;
}

.bg-black {
  background-color: $black;
}

.mr-n20 {
  margin-right: -20px;
}

.w-xl-80 {
  @include media-breakpoint-up(lg) {
    width: 80%;
  }
}

.lt-1 {
  letter-spacing: 0.01em;
}


img.googlelogo {
  width: 100%;
  height: 100%;
  max-width: 140px;
  margin-top: -14px;
}