@import '../base.scss';

.not-found-section {
  position: relative;

  @include media-breakpoint-up(lg) {
    margin-top: -50px;
  }

  h1 {
    font-size: 40px;
  }

  &-bg {
    position: absolute;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
    height: 110px;

    @include media-breakpoint-up(lg) {
      height: 220px;
    }
  }
}
