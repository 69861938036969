// Converts px values in rem
@function rem($size, $base: 16px) {
  @return #{$size / $base}rem;
}

// input-placeholder with vendor prefix
@mixin input-placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  
  &:-ms-input-placeholder {
    @content;
  }
  
  &::placeholder {
    @content;
  }
}
