@import '../base';

.communication-section {
  position: relative;
  height: rem(187px);
  margin-bottom: -2px;

  @include media-breakpoint-up(lg) {
    height: rem(673px);
  }

  @include media-breakpoint-between(md, md) {
    height: rem(343px);
  }

  @media (min-width: $breakpoing-xxl) {
    height: rem(890px);
  }

  & > video {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h2 {
    font-size: rem(38px);
    display: none;

    @include media-breakpoint-up(lg) {
      font-size: rem(75px);
      margin-bottom: rem(100px);
    }

    @media (max-width: $breakpoint-xxs) {
      font-size: rem(28px);
      margin-top: rem(15px);
    }
  }
}
