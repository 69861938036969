@import '../base.scss';
@import './btn.scss';

.our-client-section {
  position: relative;
  margin-top: -220px;
  background: url(../../assets/images/bg-black-l.png) no-repeat top center/cover;
  height: 684px;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 2;

  @media (min-width: $breakpoing-xxl) {
    height: 754px;
  }

  @media (min-width: $breakpoing-xxxl) {
    height: 934px;
  }

  @include media-breakpoint-down(md) {
    display: block;
    padding-top: 110px;
    margin-top: -190px;
    background-position: 6% 0;
  }

  h2 {
    font-size: rem(21px);
    color: $gray-800;
    margin-bottom: 1em;
  }

  &-list {
    max-height: 320px;
    overflow-y: auto;

    @include media-breakpoint-down(md) {
      max-height: 420px;
      padding-bottom: 50px;
    }

    @include custom-scrollbar();
  }

  img:not(:hover) {
    filter: grayscale(1);
  }
}
