$color-red: #ed1a3b;
$color-pink: #ec1b52;
$color-orange: #eb4925;
$color-red: #ed3837;
$color-pink: #d5184a;
$color-blue: #004282;
$color-orange-light: #f58532;
$color-base: #2d2d2d;
$gray-lighten: #eceaee;
$gray-800: #6d6f71;

$font-family-base: 'euphorigenic', Georgia, serif;

$breakpoint-xxs: 413px;
$breakpoing-xxl: 1600px;

$breakpoing-xxxl: 1900px;
