@import '../base.scss';

.testimonials {
  &-section {
    position: relative;
    z-index: 1;
    margin-top: rem(-260px);
    background: url(../../assets/images/orange-shape-bg.png) no-repeat top
      center/cover;
    min-height: rem(750px);
    padding-top: rem(180px);
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
      min-height: rem(550px);
    }

    @media (min-width: $breakpoing-xxl) {
      padding-top: rem(200px);
      min-height: rem(630px);
    }
  }

  &-slider {
    margin: 0 auto;
    position: relative;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    $w: 185px;

    &-circle {
      position: absolute;
      width: rem($w);
      height: rem($w);
      border-radius: 50%;
      top: 50%;
      background: $color-orange;
      box-shadow: 1px 1px 2px 6px $color-orange-light;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-size: rem(20px);

      @include media-breakpoint-down(lg) {
        position: static;
        width: auto;
        height: auto;
        background: transparent;
        border-radius: 0;
        box-shadow: none;
        justify-content: flex-start;
        transform: none !important;
        font-size: rem(28px);
        margin: 0 0 0.5em;

        & > span {
          transform: none !important;
        }
      }

      &.left {
        transform: translateY(-50%) scaleX(1.1) scaleY(0.95) skewX(12deg)
          skewY(-8deg);
        left: rem(-$w / 2);

        & > span {
          transform: scaleX(1) scaleY(1) skewX(-12deg) skewY(8deg);
        }
      }

      &.right {
        transform: translateY(-50%) scaleX(1.1) scaleY(0.95) skewX(-12deg)
          skewY(8deg);
        right: rem(-$w / 2);

        & > span {
          transform: scaleX(1) scaleY(1) skewX(12deg) skewY(-8deg);
        }

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }

    &-body {
      background: $white;
      margin: 0 auto;
      padding: rem(15px) rem(143px);
      height: 150px;

      @include media-breakpoint-down(lg) {
        padding: rem(20px) rem(15px);
        height: auto;
        border-radius: 10px;
      }
    }

    &-line {
      color: $color-orange;
      margin: 0;
    }

    &-byline {
      color: $black;
      margin: 0;
      text-align: right;
      font-size: rem(18px);
    }
  }
}

.slick-slide > div > div {
  outline: none;
}
