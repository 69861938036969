@import '../base.scss';

.gallery-modal {
  position: fixed;
  z-index: 1070;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &-backdrop {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba($black, 1);
  }

  &-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    min-height: calc(100vh - 80px);
  }

  .image-gallery-content .image-gallery-slide .image-gallery-video {
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    width: 100%;
    object-fit: contain;
  }

  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 60px;
    object-fit: contain;
  }

  .image-gallery-thumbnail .image-gallery-thumbnail-icon {
    font-size: 30px;
    position: absolute;
    color: $white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
