@import '../base.scss';

.navigation {
  display: flex;

  &-list {
    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &-container {
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    margin-top: 40px;
    padding: 80px 30px;
    z-index: 10;
    transition: transform 300ms ease, visibility 300ms ease;
    transform-origin: top;
    transform: scaleY(0);
    visibility: hidden;

    @include media-breakpoint-down(md) {
      padding: rem(20px);
      margin-top: rem(20px);
      background: rgba($color-pink, 0.95);
    }

    &.is-open {
      transform: scaleY(1);
      visibility: visible;
    }

    & > svg {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }

  &-item {
    @include media-breakpoint-up(lg) {
      margin: 0 20px;
    }
  }

  &-link {
    display: block;
    border: 1px solid $color-red;
    color: $color-red;
    margin: 0 rem(5px);
    font-size: rem(16px);
    padding: rem(4px) rem(13px);
    transition: background 150ms ease, color 150ms ease;
    background: none;

    @include media-breakpoint-up(lg) {
      padding: rem(10px) rem(20px);
    }

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      color: $white;
      background: $color-red;
      text-decoration: none;
    }

    &-item {
      font-size: 17px;
      display: block;
      color: $white;
      padding: 4px 0;
      transition: color 200ms ease-in-out;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
        padding: 8px 0;
      }

      &:hover {
        text-decoration: none;
        color: $black;
      }
    }
  }

  &-content {
    font-size: 18px;
    color: $white;
    max-width: rem(750px);
    line-height: 1.5em;
  }
}
